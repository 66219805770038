import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="promo-code"
export default class extends Controller {
  static targets = ["couponCodeField", "couponStatus", "form"];

  static values = {
    orderId: String,
    orderToken: String,
    success: String,
    applied: String,
  };

  connect() {}

  onCouponCodeApply(e) {
    e.preventDefault();

    let couponCode = $.trim(this.couponCodeFieldTarget.value);
    if (couponCode === "") {
      return;
    }

    const successClass = "promo_success";
    const errorClass = "promo_error";

    this.couponStatusTarget.classList.remove([successClass, errorClass]);

    let data = JSON.stringify({
      order_token: this.orderTokenValue,
      coupon_code: couponCode,
    });

    let req = Spree.ajax({
      method: "POST",
      contentType: "application/json",
      url: Spree.pathFor("api/orders/" + this.orderIdValue + "/coupon_codes"),
      data: data,
    });

    req.done((data) => {
      this.couponCodeFieldTarget.value = "";
      this.couponStatusTarget.innerHTML = this.successValue;
      this.couponStatusTarget.classList.add(successClass);
      this.formTarget.requestSubmit();
      // Turbo.visit(Spree.pathFor("/cart"))
    });

    req.fail((xhr) => {
      let handler = xhr.responseJSON;
      this.couponStatusTarget.innerHTML = handler["error"];
      this.couponStatusTarget.classList.add(errorClass);
    });
  }

  onCouponCodeRemove(e) {
    e.preventDefault();

    let data = JSON.stringify({
      order_token: this.orderTokenValue,
      coupon_code: this.appliedValue,
    });

    let successClass = "success";
    let errorClass = "alert";

    let req = Spree.ajax({
      method: "DELETE",
      contentType: "application/json",
      url: Spree.pathFor("api/orders/" + this.orderIdValue + "/coupon_codes/" + this.appliedValue),
      data: data,
    });

    req.done((data) => {
      this.couponCodeFieldTarget.value = "";
      this.couponStatusTarget.innerHTML = this.successValue;
      this.couponStatusTarget.classList.add(successClass);
      Turbo.visit(Spree.pathFor("/cart"));
    });

    req.fail((xhr) => {
      let handler;
      // handler = JSON.parse(xhr.responseText)
      handler = xhr.responseJSON;
      this.couponStatusTarget.innerHTML = handler["error"];
      this.couponStatusTarget.classList.add(errorClass);
    });
  }
}
