import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="product-image"
export default class extends Controller {
  static targets = ["activeImage", "activeVideo", "thumb", "thumbVideo"];
  static values = {
    videoId: String,
  };
  connect() {
    this.hide(this.activeVideoTarget);
    if (document.getElementById("product-thumbnails")) {
      if (this.videoIdValue) {
        this.show(this.thumbVideoTarget);
      } else {
        this.thumbVideoTarget && this.thumbVideoTarget.remove();
      }
    }
    // this.vimeo()
  }

  currentImg() {
    return this.activeImageTarget.querySelector("img");
  }

  showImg() {
    this.hide(this.activeVideoTarget);
    this.show(this.activeImageTarget);

    this.currentImg().src = event.currentTarget.src;
    this.currentImg().dataset.zoom = event.currentTarget.src;
  }

  showVideo() {
    this.hide(this.activeImageTarget);
    this.show(this.activeVideoTarget);
  }

  // could be removed - works from backend
  // vimeo() {
  //   if (!!this.videoIdValue) { this.show(this.thumbVideoTarget) } else { this.hide(this.thumbVideoTarget); return }
  //
  //   $.ajaxSetup({ headers: {} });
  //
  //
  //   $.ajax({
  //     type:'GET',
  //     headers: {},
  //     url: "https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/" + this.videoIdValue + "&width=480&height=360",
  //     success: (data) => {
  //       this.thumbVideoTarget.innerHTML = '<img src="' + data.thumbnail_url + '"/>'
  //       this.activeVideoTarget.innerHTML = data.html
  //     }
  //   });
  //   this.hide(this.activeVideoTarget)
  // }

  show(element) {
    element.hidden = false;
  }
  hide(element) {
    element.hidden = true;
  }
}
