import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// Connects to data-controller="order"
export default class extends Controller {
  connect() {
    this.modal = new Modal($("#addToCartModal")[0], {});
  }

  open() {
    if (!this.modal.isOpened) {
      this.modal.show();
    }
  }

  close(event) {
    if (event.detail.success) {
      this.modal.hide();
    }
  }

  populate() {}
}
