import { Controller } from "@hotwired/stimulus";
import "bootstrap";

// Connects to data-controller="bs-modal"
export default class extends Controller {
  static values = {
    openOnLoad: { type: Boolean, default: false },
  };
  connect() {
    if (this.openOnLoadValue) this.showModal();
  }

  showModal() {
    $(this.element).modal("show");
  }

  hideModal() {
    $(this.element).modal("hide");
  }

  disconnect() {
    this.hideModal();
  }
}
