// This is a replacement for the built-in Rails ujs confirmation method when using Bootstrap.
// Trying to hook into the built-in stuff is a pain, and changes with every Rails (now Hotwire)
// version.
import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    title: String,
    message: String,
    okButton: String,
    cancelButton: String,
    url: String,
    method: String,
    frame: String,
  };

  connect() {
    // console.log("Confirm controller connect");
  }

  click(event) {
    event.preventDefault();

    let title = this.titleValue;
    let msg = this.messageValue;
    let ok = this.okButtonValue;
    if (!ok) {
      ok = "OK";
    }
    let cancel = this.cancelButtonValue;
    if (!cancel) {
      cancel = "Cancel";
    }
    let url = this.urlValue;
    let method = this.methodValue;
    if (!method) {
      method = "get";
    }
    let frame = this.frameValue;
    if (frame) {
      frame = `data-turbo-frame="${frame}"`;
    }

    let dMethod = method === "get" ? "" : ' data-turbo-method="' + method + '"';

    let html = `
      <div class="modal fade" id="confirmDialog" tabindex="-1" role="dialog" aria-labelledby="ariaConfirmModal" 
      aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmModalTitle">${title}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              ${msg}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" id="confirmModalCancelButton" 
              data-bs-dismiss="modal">${cancel}</button>
              <a href="${url}" ${dMethod} class="btn btn-primary"
                ${frame} data-bs-dismiss="modal"
                id="confirmModalConfirmButton">${ok}</a>
            </div>
          </div>
        </div>
      </div >
      `;

    let id = "confirmDialog";
    let modalClass = "modal-confirm";
    let fade = "fade";
    html = `
       <div id="${id}" class="modal ${modalClass} ${fade}" tabindex="-1" role="dialog" 
          aria-labelledby="${id}Label" aria-hidden="true"> 
          <div class="modal-dialog" role="document"> 
              <div class="modal-content"> 
                  <div class="modal-header">
                    <h5 id="${id}Label" class="modal-title">${title}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                  </div> 
                  <div class="modal-body"></div> 
                  <div class="modal-footer"> 
                      <a href="${url}" ${dMethod} class="btn commit btn-primary"
                       id="confirmModalOkButton">${ok}</a>
                      <button class="btn cancel btn-outline" id="confirmModalCancelButton" 
                      data-dismiss="modal" aria-hidden="true">${cancel}</button> 
                  </div> 
              </div> 
          </div> 
      </div>`;

    let tempDiv = document.createElement("div");
    tempDiv.id = "temp-html";
    tempDiv.innerHTML = html;
    document.body.append(tempDiv);

    let m = document.getElementById(id);
    let confirmModal = new Modal(m);
    confirmModal.show();

    $(m).on("hidden.bs.modal", function () {
      tempDiv.remove();
    });

    document.getElementById("confirmModalOkButton").addEventListener("click", () => {
      confirmModal.hide();
    });
  }
}
