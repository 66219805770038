import Drift from 'drift-zoom';
import 'drift-zoom/dist/drift-basic.css';

document.addEventListener('turbo:load', function () {
  let trigger = document.querySelector('.product-img img');
  let paneContainer = document.querySelector('.zoom-image');

  if (trigger && paneContainer) {
    new Drift(trigger, {
      paneContainer: paneContainer,
      inlinePane: false,
    });
  }
});
