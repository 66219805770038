import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="product"
export default class extends Controller {
  static targets = ["quantityInput"];

  connect() {}

  quantityUp(e) {
    e.preventDefault();
    this.quantityInputTarget.stepUp();
  }

  quantityDown(e) {
    e.preventDefault();
    this.quantityInputTarget.stepDown();
  }
}
