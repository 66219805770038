import { Turbo } from "@hotwired/turbo-rails";
import Choices from "choices.js";
window.Choices = Choices;

document.addEventListener("turbo:load", function () {
  const element = document.querySelector(".js-single");

  if (element) {
    new Choices(element, {
      itemSelectText: "",
      placeholder: true,
      searchEnabled: false,
    });
    element.addEventListener(
      "change",
      function () {
        const elem = $(".filter_wrap")[0];
        Turbo.navigator.submitForm(elem);
      },
      false
    );
  }

  const cats = document.querySelector(".js-category");
  if (cats) {
    new Choices(cats, {
      itemSelectText: "",
      placeholder: true,
      searchEnabled: false,
    });

    cats.addEventListener(
      "choice",
      function (event) {
        Turbo.visit(JSON.parse(event.detail.choice.customProperties).url);
      },
      false
    );
  }
});
