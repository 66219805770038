import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="line-item"
export default class extends Controller {
  static targets = ["quantityInput"];

  quantityUp(e) {
    e.preventDefault();
    this.quantityInputTarget.stepUp();
    this.submit();
  }

  quantityDown(e) {
    e.preventDefault();
    this.quantityInputTarget.stepDown();
    this.submit();
  }

  submit() {
    this.element.closest("form").requestSubmit();
  }
}
