import { Turbo } from "@hotwired/turbo-rails";
Turbo.setProgressBarDelay(10);

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "../jquery.js";
import("jquery-serializejson");

import "bootstrap";

import "../src/toastr-options";
import "../src/mobile-menu";
import "../src/main";
import "../src/form-styler";
import "../src/search";
import "../src/drift-zoom";

import "../controllers";

// LEGACY
// import "../src/address";
// import '../src/one_step_checkout';
// import "@client-side-validations/client-side-validations";
