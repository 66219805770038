import { Controller } from "@hotwired/stimulus";
import toastr from "toastr";
toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-center",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "100000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

// Connects to data-controller="toastr"
export default class extends Controller {
  static values = {
    msg: String,
    type: String,
  };
  connect() {
    toastr[this.typeValue](this.msgValue);
  }
}
