import Choices from "choices.js";

document.addEventListener('turbo:load', function () {
  $('.toggle-sidebar').click(function () {
    $('#navigation').toggleClass('show');
    $('body').toggleClass('modal-open');
  });

  if ($(".select-style")[0]) {
    new Choices($('.select-style')[0]);
  }
});

let preLastTouchStartAt = 0;
let lastTouchStartAt = 0;
const delay = 500;

document.addEventListener('touchstart', function () {
  preLastTouchStartAt = lastTouchStartAt;
  lastTouchStartAt = +new Date();
});
document.addEventListener('touchend', function (event) {
  const touchEndAt = +new Date();
  if (touchEndAt - preLastTouchStartAt < delay) {
    event.preventDefault();
  }
});

$(function () {
  if ($(window).width() < 767) {
    $('.divTableHeading .divTableRow').prependTo('.divTableBody');
  }
});
