$(document).ready( function() {
    $('.dropdown-submenu-mobile').click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(this)
            .find('.dropdown-menu')
            .first()
            .toggle();
    });
});
